@import './../../../scss/variables/index.scss';

#loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 0;
	height: 2px;
	background: #fff;
	z-index: 1000;
	mix-blend-mode: difference;

	&.loading {
		animation-name: siteload;
		animation-duration: 5s;
		animation-iteration-count: infinite;
	}
}

@keyframes siteload {
	from {width: 0%;}
	to {width: 100%}
}