@import './../../../scss/variables/index.scss';
@import './../../../scss/general/breakpoints.scss';

.viewport-visibility-component {
	position: relative;
	width: 100%;

	& > *:first-child:not(.media) {
		transition: transform ease 0.3s, opacity ease 0.3s;
	}

	&.hidden {
		& > *:first-child {
			transform: translateY(25px);
			opacity: 0;
		}
	}

	&.visible {
		& > *:first-child {
			transform: translateY(0);
			opacity: 1;
		}
	}

	.home & {
		min-height: 100vh;
		min-height: var(--app-height);
		background-color: $colorMainWhite;

		&:not(:first-child) {
			@include breakpoint(small) {
				min-height: 0;
				height: 66vh;
			}
		}

		&.hidden > *:first-child {
			opacity: 1;
		}
	}
}