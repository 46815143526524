@import './../../../scss/variables/index.scss';
@import './../../../scss/general/breakpoints.scss';
@import './../../../scss/fonts/fonts.scss';

.page.projects {
	.selected-projects-list {
		margin-top: 0;

		@include breakpoint(small) {
			background: #fff;
		}

		.col {
			flex-direction: column;
		}
	}



	ul.projectsview {
		display: flex;
		flex-direction: row;

		list-style: none;
		padding: 0;
		text-transform: uppercase;
		font-size: 20px;
		margin-bottom: 83px;
		margin-top: 0;

		@include breakpoint(small) {
			transform: translateZ(0);
		}

		@include breakpoint(mobileSmall) {
			font-size: 16px;
		}

		li {
			margin-right: 50px;
			position: relative;
			letter-spacing: .01em;

			&:after {
				content: '';
				position: absolute;
				bottom: -3px;
				left: 0;
				width: 100%;
				height: 2px;
				background: #000;
				transition: transform ease-in-out 0.3s;
				transform: scaleX(0);
				transform-origin: left
			}

			&:hover, &.selected {
				&:after {
					transform: scaleX(1)
				}
			}
		}
	}
	
	.projectlist {
		margin: 0;
		padding: 0;
		list-style-type: none;
		font-size: 3.7vw;
		text-transform: uppercase;
		z-index: 10;
		font-family: $fontLight;
		mix-blend-mode: difference;

		@include breakpoint(small) {
			font-size: 10vw;
			width: 100%;
			word-break: break-word;
		}

		li {
			margin-bottom: 40px;
			line-height: 1.2;
			position: relative;

			@include breakpoint(small) {
				padding-right: 20px;
			}

			&:first-child {
				margin-top: -0.7vw;
			}

			a {
				color: $colorBorder;
				transition: color ease .3s;
				display: flex;

				@include breakpoint(small) {
					color: #fff;
					mix-blend-mode: difference;
				}


				span {
					transition: transform ease .3s;
					position: relative;

					&:after {
						content: '';
						width: 100%;
						height: 100%;
						padding-top: 10px;
						background: #fff;
						bottom: 0;
						left: 0;
						position: absolute;
						mix-blend-mode: difference;
						transform: scaleX(0);
						transform-origin: left;
						transition: transform ease-in-out 0.5s;
						pointer-events: none;

						@include breakpoint(small) {
							display: none;
							// position: relative;
						}
					}
				}
			}

			&:hover {
				a {
					span {
						transform: translateX(50px);		

						@include breakpoint(small) {
							transform: none;		
						}
					}
				}
			}

			&.active {
				a span {
					&:after {
						transform: scaleX(1);
					}
				}

				@include breakpoint(small) {
					a span {
						transform: none;		
					}
				}
			}

			&.alt {
				margin-bottom: 90px;
				
				a {
					color: #000;

					span:after {
						display: none;
					}
				}
				
			}

		}
	}

	.highlighted-project-image {
		display: flex; 
		justify-content: center;
		align-items: flex-start;

		@include breakpoint(small) {
			// position: fixed;
			// top: 20px;
			// transform: translateZ(0);
		}

		.inner {
			.image-wrapper {
				position: relative;
				width: 100%;
				height: 0;
				padding-bottom: 75%;

				@include breakpoint(small) {
					position: fixed;
					right: 20px;
					width: 70% !important;
					margin-top: 100px;
					transform: translateZ(0) 
				}

				&.fixed {
					position: fixed;
				    // width: calc((100% - 15px - 47px - 10px) * 0.375- 20px);
				    // width: 50%;
				    // height: 25.4vw;
				    padding-bottom: 0;
				    top: 50%;
				    transform: translateY(-50%);
				}

				img {
					position: absolute;
					width: 100%;
					height: auto;
					z-index: 1;
					opacity: 0;

					&:after {
					}

					&.selected-project-thumb-enter {
						opacity: 0;
						transform: translateY(5px)
					}

					&.selected-project-thumb-enter-active {
						opacity: 1;
						transition: all ease 0.45s 0.1s;
						transform: translateY(0px)
					}

					&.selected-project-thumb-enter-done {
						opacity: 1;
						transform: translateY(0px)
					}

					&.selected-project-thumb-exit {
						opacity: 1;
						transform: translateY(0px)
					}

					&.selected-project-thumb-exit-active {
						opacity: 0;
						transition: all ease 0.4s;
						transform: translateY(-5px)
					}

					&.selected-project-thumb-exit-done {
						opacity: 0;
						transition: all ease 0.6s;
						transform: translateY(-5px)
					}
				}
			}
		}
		
	}

	.more-projects-link {
		position: fixed;
		right: 20px;
		top: 100px;
	}
}
