@import '../../../scss/variables/index';
@import '../../../scss/fonts/fonts';
@import '../../../scss/general/breakpoints';

.title-text {
	grid-column-end: span 16;
  position: fixed;
	left: 0;
	z-index: 1;
	width: 100%;
	top: $pageGutterTablet;
	top: 10rem;
	padding-top: calc(2em + #{$pageGutterTablet});
	padding-top: 0;
	padding-right: $pageGutterTablet;
	padding-bottom: 0;
	padding-left: $pageGutterTablet;  
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  line-height: 1.2;
	pointer-events: none;
	
  @include breakpoint(small) {
		top: 4.0625rem;
		top: 6.3125rem;
    // bottom: $pageGutter;
    padding-left: $pageGutter;
  }

  @include breakpoint(xlarge) {
    right: $gap;
    padding-right: $gap;
  }

	// &.keepVisible {
	// 	position: sticky;
	// 	bottom: 0;

	// 	.title-text__inner {
	// 		bottom: 2.5rem;
	// 	}		

	// 	@include breakpoint(small) {
	// 		position: absolute;

	// 		.title-text__inner {
	// 			bottom: auto;
	// 		}
	// 	}
	// }

	.home .page-section:first-child {
		// display: flex;
		// flex-direction: column;
	}
	
	.home .sections > &:nth-child(2) {
		position: sticky;
		top: 10rem;
		// order: -1;
		margin-top: -.75rem;
		margin-bottom: .75rem;

		@include breakpoint(small) {
			top: 6.11rem;
		}
	}

	.l-columns-container--outmost & {
		padding-right: 0;
		padding-left: 0;
	}

	*:not(.visible):not(.hidden) + & {
		pointer-events: auto;
	}
  
	.hidden + &,
	&.hidden {
    pointer-events: none;
  }

	.visible + &,
	&.visible {
    pointer-events: auto;
  }

	&__inner {
		position: absolute;
	}

  &__title,
	&__link {
		transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
		will-change: opacity, transform;
		white-space: nowrap;

		&,
		&-exit-active,
		&-exit-done {
			opacity: 0;
		}

		.visible + .title-text &-enter {
		// &-enter {
			&-active,
			&-done {
				opacity: 1;
				transition-duration: 150ms;
				transition-delay: 300ms;
			}
		}

		.bright {
			color: #fff;
		}
	}

	&__title {
		text-transform: none;

		// &-enter {
		// 	opacity: 0;
		// 	transform: translateX(10px);
		// }

		// &-enter-active {
		// 	opacity: 1;
		// 	transform: translateX(0);
		// }

		.visible + .title-text &
		&-enter-done {
			opacity: 1;
			transform: translateX(0);	
		}

		// &-exit {
		// 	opacity: 1;
		// 	transform: translateX(0);
		// }

		// &-exit-active {
		// 	opacity: 0;
		// 	transform: translateX(-10px);
		// }

		.hidden + .title-text &,
		&-exit-done {
			opacity: 0;
			transform: translateX(-10px);
		}
	}

	&__link {
		position: relative;
		margin-top: 0.25rem;
		text-transform: uppercase;

		// &-enter {
		// 	opacity: 0;
		// 	transform: translateX(-.625rem)
		// }

		// &-enter-active {
		// 	opacity: 1;
		// 	transform: translateX(0)
		// }

		.visible + .title-text &,
		&-enter-done {
			opacity: 1;
			transform: translateX(0);
		}

		// &-exit {
		// 	opacity: 1;
		// 	transform: translateX(0)
		// }

		// &-exit-active {
		// 	opacity: 0;
		// 	transform: translateX(.625rem)
		// }

		.hidden + .title-text &,
		&-exit-done {
			opacity: 0;
			transform: translateX(.625rem);
		}

		&__link {
			display: flex;
			align-items: flex-start;
		}

		&__arrow {
			margin-right: 0.1em;

			svg {
				height: .75em;
			}
		}

		&__text {
			display: inline-block;
		}
	}
}