@import './../../../scss/variables/index.scss';
@import './../../../scss/general/breakpoints.scss';

.active-filter-tags {
	margin-bottom: 61px;
	display: flex;
	flex-wrap: wrap;
	
	&.hide {
		display: none;
	}
	
	@include breakpoint(xlarge) {
		margin-top: 0;
	}

	.term {
		padding: 12px 44px 12px 20px;
		border: solid $colorBorder 2px;
		margin-right: 10px;
		margin-bottom: 10px;
		border-radius: 50px;
		position: relative;
		display: flex;
		transition: all ease .2s;

		&:after, &:before {
			content: '';
			position: absolute;
			height: 2px;
			width: 20px;
			background: #000;
			top: 50%;
			right: 14px;
			transition: all ease .2s;
		}

		&:after {
			transform: rotateZ(45deg);
		}

		&:before {
			transform: rotateZ(-45deg);
		}

		&:hover {
			background: #000;
			color: #fff;
			border-color: #000;

			&:after, &:before {
				background: #fff;
			}

			&:after {
				transform: rotateZ(135deg);
			}

			&:before {
				transform: rotateZ(45deg);
			}	
		}
	}
}
