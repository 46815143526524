@import '../../scss/variables/index.scss';
@import '../../scss/general/breakpoints.scss';

.App {
	position: absolute;
	width: 100%;
	height: 100%;
	background: #fff;
}

* {
	// cursor: none !important;
}

.l-columns-container {
	display: grid;
	grid-template-columns: repeat(16, 1fr);
	gap: $gapTablet;
	
	@include breakpoint(small) {
		grid-template-columns: repeat(4, 1fr);
		gap: $gap;
	}

	&--outmost {
		width: 100%;
		// max-width: 1680px;

		padding-right: $pageGutterTablet;
		padding-left: $pageGutterTablet;	
		
		@include breakpoint(small) {
			padding-right: $pageGutter;
			padding-left: $pageGutter;
		}

		.open & {
			@include breakpoint(desktop) {
				width: 50%;
			}
		}
	}
}

.one-column {
	&--center &__first {
		grid-column-start: 5;
		grid-column-end: span 8;	
	}
}

.two-columns {
	&__first {
		grid-column-start: 1;
		grid-column-end: span 7;
	}
	
	&__second {		
		grid-column-start: 11;
		grid-column-end: span 6;
	}
}

.three-columns {
	align-items: center;

	&__first {
		grid-column-start: 1;
		grid-column-end: span 4;

		@include breakpoint(small) {
			grid-column-start: 1;
			grid-column-end: span 3;
		}
	}

	&__second {
		grid-column-start: 6;
		grid-column-end: span 3;
		
		@include breakpoint(small) {
			grid-column-start: 2;
			grid-column-end: span 3;
		}
	}

	&__third {
		grid-column-start: 12;
		grid-column-end: span 5;

		@include breakpoint(small) {
			grid-column-start: 1;
			grid-column-end: span 3;
		}
	}
}