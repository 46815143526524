@font-face {
    font-family: 'good_sansblack';
    src: url('goodsans-black-webfont.woff2') format('woff2'),
         url('goodsans-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}




@font-face {
    font-family: 'good_sansblack_italic';
    src: url('goodsans-blackitalic-webfont.woff2') format('woff2'),
         url('goodsans-blackitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'good_sansbold';
    src: url('goodsans-bold-webfont.woff2') format('woff2'),
         url('goodsans-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'good_sansbold_italic';
    src: url('goodsans-bolditalic-webfont.woff2') format('woff2'),
         url('goodsans-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'good_sanslight';
    src: url('goodsans-light-webfont.woff2') format('woff2'),
         url('goodsans-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'good_sanslight_italic';
    src: url('goodsans-lightitalic-webfont.woff2') format('woff2'),
         url('goodsans-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'good_sansmedium';
    src: url('goodsans-medium-webfont.woff2') format('woff2'),
         url('goodsans-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'good_sansmedium_italic';
    src: url('goodsans-mediumitalic-webfont.woff2') format('woff2'),
         url('goodsans-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'good_sansregular';
    src: url('goodsans-regular-webfont.woff2') format('woff2'),
         url('goodsans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'good_sansregular_italic';
    src: url('goodsans-regularitalic-webfont.woff2') format('woff2'),
         url('goodsans-regularitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'good_sansthin';
    src: url('goodsans-thin-webfont.woff2') format('woff2'),
         url('goodsans-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'good_sansthin_italic';
    src: url('goodsans-thinitalic-webfont.woff2') format('woff2'),
         url('goodsans-thinitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}