@import './../../../scss/variables/index.scss';
@import './../../../scss/general/breakpoints.scss';

.archivefilter {
	.inner {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: $gapTablet;

		@include breakpoint(xlarge) {
			grid-template-columns: repeat(4, 1fr);
		}

		@include breakpoint(large) {
			grid-template-columns: repeat(2, 1fr);
		}

		@include breakpoint(small) {
			grid-template-columns: 1fr;
			gap: $gap;
		}
	}

	.title {
		margin-bottom: 1.875rem;
		color: #000;
		text-transform: uppercase;

		@include breakpoint(small) {
			margin-bottom: 1rem;
			font-size: 1.5rem;
		}
	}

	.filter-col:not(:last-child) {
		margin-bottom: 3.25rem;
	}

	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	li {
		a:hover,
		&.active a {
			opacity: 1;
		}
	}

	a {
		opacity: .4;
		will-change: opacity;
		transition: opacity 200ms ease-out;
	}

}