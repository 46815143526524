@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

	// LOGO
		svg {
			g {
				&#logo_rodeo, &#logo_architecture, &#logo_architecture_alt {
					fill: #000 !important;	
					path, polygon {
						fill: #000 !important;	
					}
				}

			}
		}

		.menu-open {
			svg {
				g {
					&#logo_rodeo, &#logo_architecture, &#logo_architecture_alt  {
						fill: #fff !important;	

						path, polygon {
							fill: #fff !important;	
						}
					}
				}
			}			
		}

	// CURSOR

		#cursor {
			.pointer {
				color: #000 !important;
				border-color: #000 !important;
				
				&:after {
					background: #000 !important;
				}
			}

			.link, .scroll {
				.arrow {
					background: #000 !important;

					&:before, &:after {
						background: #000 !important;
					}
				}
			}
		}

	// LINK

		a {
			color: #000 !important;
		}

	// NAVIGATION
		.navigation-wrapper {
			&.open {
				svg#menudots {
					#dot1, #dot2, #dot3 {
						fill: #fff;
					}
				}
			}

			svg#menudots {
				#dot1, #dot2, #dot3 {
					fill: #000;
				}
			}

			.navigationmain ul li a {
				color: #fff !important;

				&:hover, &.active {
					span {
						transform: none !important;
					}
				}

				svg {
					display: none !important;
				}
			}
		}


	// FRONTPAGE
		.page.frontpage .frontpage-slider .slidedata {
			color: #000 !important;

			.title, .title .inner {
				color: #000 !important;
			}
		}

		.page.frontpage .frontpage-slider .slidedata .link .inner .lassotitle.poster {
			color: #000 !important;
		}


	// PROJECTS
		.page.projects .projectlist li a span {
			float: left !important;
			max-width: 500px !important;
			// position: relative !important;

			&:after {
				display: none !important;
			}
		}

	// ARCHIVE
		.toggle-sidebar-btn.toggle-filter-btn .inner {
			left: 50%;
			transform: translateX(-50%);
		}

		.toggle-sidebar-btn.toggle-filter-btn:hover .inner, .toggle-sidebar-btn.toggle-filter-btn.open .inner {
			transform: translateX(-50%) rotateZ(0deg) translateZ(0px) !important;
		}

		.page.project-archive .page-section.headline .col.title {
			display: block;
		}

		.sidebar.archivefilter .inner .filter-col.active li {
			&.active a {
				color: #000 !important;
			}

			a {
				color: #ccc !important;

				&:hover {
					color: #000 !important;
				}
			}

		}

		.active-filter-tags .term:hover {
			color: #fff !important;
		}

	// SINGLE PROJECT
		.page.project-single .page-section .col {
			display: block;
		}

	// PEOPLE
		.peoplesingle .sidebar .contact li a:hover {
			color: #fff !important;
		}

	// CONTACT
		.page.contact .page-section.template1 p a:hover {
			color: #fff !important;
		}

}