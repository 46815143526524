@import './../../../scss/general/breakpoints.scss';
@import './../../../scss/variables';

.page.studio, .page.contact {
	.page-section.studio, .page-section.contact {
		padding-top: 56px;
		padding-bottom: 56px;
		margin-bottom: 0;

		.row {
			width: 100%;
			display: flex;

			@include breakpoint(small) {
				flex-direction: column;

				&.row-fl-r-s {
					flex-direction: row;	
				}
			}

		}

		.col {
			&.bottom {
				.text {
					p:first-of-type {
						margin-top: 45px;

						@include breakpoint(small) {
							margin-top: 0;
						}
					}	
				}
			}

			.text {
				p:last-of-type {
					margin-bottom: 0;
				}

				@include breakpoint(small) {
					// margin-top: $pageSectionMarginBottomMobile;
					margin-bottom: $pageSectionMarginBottomMobile;
				}
			}

			.lazy-image-component {
				margin-bottom: $pageSectionMarginBottomMobile;
			}
		}

		&.template1 {
			.col {
				.text {
					&.top {
						margin-bottom: 160px;

						@include breakpoint(small) {
							margin-bottom: $pageSectionMarginBottomMobile;
						}
					}
				}
			}
		}

		&.template3 {
			.text {
				margin-top: 50px;

				@include breakpoint(small) {
					margin-top: 0;
				}
			}
		}
	}

	.page-section.headline {
		.headertext {
			.linkwrapper {
				display: inline-block;
			}
		}
	}
}