@import './../../../scss/general/breakpoints.scss';

.page.project-single .page-section.related-projects {
	@include breakpoint(medium) {
		.col33:nth-child(3) {
			display: none;
		}
	}

	.section-title {
		position: relative !important;
		margin-bottom: 48px;
		width: 100%;

		@include breakpoint(small) {
			margin-bottom: 22px;
		}
	}
}