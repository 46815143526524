// @import '../general/breakpoints.scss';

.page {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	// background: #fff;

	&.no-headline {
		padding-top: $pagePaddingTopMain;

		@include breakpoint(large) {
			padding-top: $pagePaddingTopMainMobile;
		}
	}

	&.post .sections {
		margin-bottom: 17.5rem;

		@include breakpoint(small) {
			// margin-bottom: 5rem;
			margin-bottom: 0;
		}
	}


	&.normal {
		.page-section {
			&.full {
				img {
					width: 100%;
					height: auto;
				}
			}

			.col {
				img {
					width: 100%;
					height: auto;
				}
			}
		}
	}

	.page-section {
		&.border-bottom {
			border-bottom: solid 2px $colorBorder;
		}

		&.border-top {
			border-top: solid 2px $colorBorder;
		}

		&.headline {

			a {
				text-decoration: none;
				position: relative;

				&:after {
					content: '';
					position: absolute;
					width: 100%;
					height: 0.3vw;
					left: 0;
					bottom: 0.7vw;
					background: #000;
				}
			}

			@include breakpoint(large) {
				a:after {
					bottom: 8px;
				}
			}

			@include breakpoint(mobile) {
				a:after {
					bottom: 1.5vw;
					height: 2px;
				}
			}
		}

		&.full {
			padding: 0;
			// margin: 0;
		}

		&.related-projects {
			padding-top: 50px;
			margin-bottom: 50px !important;
		}

		.section-title {
			position: absolute;
			text-transform: uppercase;
			font-size: 20px;
			margin-left: $gridColMargin;
			font-family: $fontMain;
			transform: translateX(-1px);

			@include breakpoint(small) {
				position: relative;
				margin-bottom: $pageSectionMarginBottomMobile;
				line-height: 1.3;
			}

			@include breakpoint(mobileLarge) {
				margin-left: 0;
			}
		}

		.col {
			padding-left: $gridColMargin;
			padding-right: $gridColMargin;
			display: flex;

			@include breakpoint(mobileLarge) {
				padding-left: $gridColMarginMob;
				padding-right: $gridColMarginMob;
			}
			

			&.bottom {
				align-self: flex-end;
			}

			&.center {
				align-self: center;
			}

			&.originator {
				font-size: 14px;
				margin-top: 20px;
			}

			&.project-thumb {
				align-items: stretch;

				a {
					width: 100%;
					// position: relative;
					
					img {
						backface-visibility: hidden;
						transform: scale(1.02) translateZ(0);
					}

					&:hover {
						// transform: translateY(-10px);

						img {
							transform: scale(1) translateZ(0);
						}

						.title {
							transform: translateX(20px);
						}
					}
				}

				.thumb {
					width: 100%;
					padding-bottom: 61.5%;
					background-repeat: no-repeat;
					background-size: cover;
					background-position: center center;
				}

				.category {
					margin-top: 24px;
					font-size: 14px;
					line-height: 1;

					@include breakpoint(xlarge) {
						font-size: 12px;
						margin-top: 20px;
					}
				}

				.year {
					font-size: 14px;
					margin-top: 4px;

					@include breakpoint(xlarge) {
						font-size: 12px;
					}
				}

				.title {
					margin-top: 21px;
					font-size: 20px;
					transition: transform ease 0.2s;
					padding-right: 20px;

					@include breakpoint(xlarge) {
						font-size: 20px;
						margin-top: 11px;
					}

					@include breakpoint(mobileLarge) {
						font-size: 4vw;
					}
				}
			}

			.inner {
				flex: 1;
				display: flex;
				flex-direction: column;
			}

			&.col33 {
				width: 33.33333%;

				.title {
					transition: font-size ease .8s, transform ease .2s;
				}

			}

			@for $i from 0 through 8 {
				&.colmar#{$i}l {
					margin-left: $gridColWidth * $i;
				}

				&.colmar#{$i}r {
					margin-right: $gridColWidth * $i;
				}
			}

			// xlarge breakpoint
			@for $i from 0 through 8 {
				&.colmar#{$i}l-xl {
					@include breakpoint(xlarge) {
						margin-left: $gridColWidth * $i;
					}
				}

				&.colmar#{$i}r-xl {
					@include breakpoint(xlarge) {
						margin-right: $gridColWidth * $i;
					}
				}
			}

			// medium breakpoint
			@for $i from 0 through 8 {
				&.colmar#{$i}l-med {
					@include breakpoint(medium) {
						margin-left: $gridColWidth * $i;
					}
				}

				&.colmar#{$i}r-med {
					@include breakpoint(medium) {
						margin-right: $gridColWidth * $i;
					}
				}
			}

			// small breakpoint
			@for $i from 0 through 8 {
				&.colmar#{$i}l-s {
					@include breakpoint(small) {
						margin-left: $gridColWidth * $i;
					}
				}

				&.colmar#{$i}r-s {
					@include breakpoint(small) {
						margin-right: $gridColWidth * $i;
					}
				}
			}

			// mobile-xl breakpoint
			@for $i from 0 through 8 {
				&.colmar#{$i}l-mobxl {
					@include breakpoint(mobileXLarge) {
						margin-left: $gridColWidth * $i;
					}
				}

				&.colmar#{$i}r-mobxl {
					@include breakpoint(mobileXLarge) {
						margin-right: $gridColWidth * $i;
					}
				}
			}

			@for $i from 1 through 16 {
				&.col#{$i} {
					width: $gridColWidth * $i;
				}

				&.col#{$i}-xl {
					@include breakpoint(xlarge) {
						width: $gridColWidth * $i;
					}
				}

				&.col#{$i}-large {
					@include breakpoint(large) {
						width: $gridColWidth * $i;
					}
				}

				&.col#{$i}-med {
					@include breakpoint(medium) {
						width: $gridColWidth * $i;
					}
				}

				&.col#{$i}-s {
					@include breakpoint(small) {
						width: $gridColWidth * $i;
					}
				}

				&.col#{$i}-mobxl {
					@include breakpoint(mobileXLarge) {
						width: $gridColWidth * $i;
					}
				}
			}

			&.hide-mobxl {
				@include breakpoint(mobileXLarge) {
					display: none;
				}
			}
			
			&.hide-s {
				@include breakpoint(small) {
					display: none;
				}
			}

		}
	}
}

.quote {
	transition: $sidebarAnimation;
	font-family: $fontLight;
	font-size: 4.5vw;
	line-height: 1.2;

	.open & {
		font-size: 2.25vw;
	}
}

.smallquote {
	font-size: 3.8vw;
	font-family: $fontLight;
	align-self: flex-start;
	line-height: 1.2;
	margin-top: -0.8vw;

	@include breakpoint(small) {
		font-size: 7vw;
		margin-bottom: 20px;
	}
}

.page-headline {
	position: relative;
	grid-column-end: span 16;
	
	@include breakpoint(small) {
		margin-top: 0.25rem;
		grid-column-end: span 16;
	}

	.cursor.active ~ div &:hover,
	.cursor.active ~ div &:hover a {
		color: #ccc;
	}

	.cursor.active ~ div & a:hover {
		color: #000;
	}

	p {
		margin: 0;
		max-width: 20em;

		@include breakpoint(xlarge) {
			font-size: 4rem;
		}

		@include breakpoint(medium) {
			font-size: 2.125rem;
		}
	}

	a {		
		&:hover {
			z-index: 2;

			img {
				opacity: 1;

				@include breakpoint(small) {
					opacity: 0;
				}
			}

			.linkwrapper__text {
				z-index: 2;
			}
		}
	}

	.linkwrapper__text {
		position: relative;
	}

	img {
		position: absolute;
		top: -105px;
		left: 0;
		z-index: 1;
		width: 100%;
		height: calc(100% + 210px);
		opacity: 0;
		object-fit: contain;
		will-change: opacity;
		transition: opacity 200ms ease-in-out;
		pointer-events: none;
	}
}

.page-section {
	position: relative;
	width: 100%;
	flex-wrap: wrap;

	.page:not(.project-single):not(.home) &:first-child {
		@include breakpoint(small) {
			margin-top: 8.75rem;
		}
  }

	.page:not(.home) &:last-child {
		@include breakpoint(small) {
			margin-bottom: 7.5625rem;
		}
	}

	.home & {
		min-height: 100vh;
		min-height: var(--app-height);
		margin-top: 0;
		margin-bottom: 0;
		background-color: $colorMainWhite;

		@include breakpoint(small) {
			height: 100%;
		}
	}

	&__inner {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		
		.home & {
			min-height: 100vh;
			min-height: var(--app-height);

			@include breakpoint(small) {
				min-height: 66vh;
			}

		}
		.home .viewport-visibility-component:first-child & {
			@include breakpoint(small) {
				min-height: 100vh;
				min-height: var(--app-height);
			}	
		}
	}



	.section-border + &,
	.section-border + .id + & {
		margin-top: 3.125rem;
	}
}

.page-section,
.section-border {
	margin-top: 15.625rem;

	@include breakpoint(small) {
		margin-top: 5.25rem;
	}
}