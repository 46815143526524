@import './../../../scss/variables/index.scss';
@import './../../../scss/general/breakpoints.scss';
@import './../../../scss/fonts/fonts.scss';

$menuTransitionDelay: 0.03;

.navigation-wrapper {
	&.open {
		.navigationmain {
			opacity: 1;
			pointer-events: auto;

			li {
				opacity: 1;
				transform: translateX(0);

				@for $i from 1 through 5 {
					&:nth-child(#{$i}) {
						transition-delay: #{$i * $menuTransitionDelay + 0.15}s;
					}
				}			
			}
		}

		&.desktop {
			#menudots #dot2 {
				transform: scale(4) !important;
			}
		}

		&.mobile {
			#menudots {
				circle {
					&#dot2 {
						transform: scale(4);
					}

					&#dot1 {
						transform: translateX(15px);
					}

					&#dot3 {
						transform: translateX(-15px);
					}
				}
			}
		}
	}

	&.desktop {
		#menudots {
			&:hover {
				circle {
					&#dot2 {
						transform: scale(3);
					}
	
					&#dot1 {
						transform: translateX(15px);
					}
	
					&#dot3 {
						transform: translateX(-15px);
					}
				}
			}
		}
	}
}

#menudots {
	position: fixed;
	top: $pageGutterTablet;
	right: $pageGutterTablet;
	z-index: 1200;
	margin-top: -20px;
	margin-right: -8px;
	padding: .5rem 14px;
	box-sizing: content-box;
	cursor: pointer;
	mix-blend-mode: difference;

	@include breakpoint(small) {
		top: 1.375rem;
		right: $pageGutter;
	}

	circle {
		transform-origin: center center;
		transition: all ease .3s;
		fill: #fff;
	}
}

.navigationmain {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1100;
	display: flex;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	background: #000;
	transition: opacity ease 300ms;
	will-change: opacity;
	opacity: 0;
	pointer-events: none;
	text-transform: uppercase;

	&__item {
		&__linkwrapper {
			position: relative;
			display: block;
			overflow: hidden;
		}

		&__link {
			position: relative;
			display: flex;
			opacity: .3;
			will-change: opacity;
			color: #fff;
			text-decoration: none;
			will-change: transform;
			transform: translateX(-1em);
			transition: transform 200ms ease-out, opacity 200ms ease-out;
	
			@include breakpoint(xlarge) {
				color: #fff;
			}

			&.active,
			&:hover {
				opacity: 1;
				transform: translateX(0em);

				.navigationmain__item__text {
					@include breakpoint(xlarge) {
						transform: none;
					}
				}
			}
		}		

		&__arrow {
			margin-right: 0.1em;
			svg {
				height: 0.75em;
			}
		}
	}

	ul {
		list-style-type: none;
		font-size: 5.625rem;
		font-family: $fontLight;
		position: absolute;
		bottom: 0;
		padding-left: calc(#{$pageGutterTablet} - .8rem);
		margin-top: 0;
		margin-bottom: 20px;

		@media screen and (min-width: 1250px) and (min-height: 1000px) {
			font-size: 7.5rem;
		}

		@include breakpoint(large) {
			font-size: 3.75rem;
		}

		@include breakpoint(small) {
			padding-left: calc(#{$pageGutter} - .2rem);
			font-size: 2rem;
		}
	}

	li {
		opacity: 0;
		transition: all ease .3s;
		transform: translateX(-5px);
		line-height: 1.125;
		letter-spacing: 0.01em;

		@for $i from 1 through 5 {
			&:nth-child(#{$i}) {
				transition-delay: #{$i * $menuTransitionDelay}s;
			}
		}

		@include breakpoint(mobile) {
			font-size: 12vw;
			line-height: 1.25;
		}
	}
}

.navigation__lang {
	position: absolute;
	z-index: 999;
	top: $pageGutterTablet;
	right: 7.5rem;
	margin-left: auto;
	mix-blend-mode: difference;
	font-size: 1.125rem;
	line-height: 1;
	
	@include breakpoint(small) {
		top: $pageGutter;
		right: 6rem;
	}
	
	.linkwrapper:not(:first-child) {
		margin-left: 1.3125rem;
		@include breakpoint(small) {
			margin-left: .75rem;
		}
	}

	a {
		color: #fff;
		opacity: .3;

		&.active {
			opacity: 1;
		}
	}
}