@import './../../../scss/variables/index.scss';
@import './../../../scss/general/breakpoints.scss';


.people-single {
	position: fixed;
	top: 0;
	width: 100vw;
	opacity: 1;
	background: rgba(255,255,255,1);
	z-index: 90;
	height: 100vh;
	display: flex;
	align-items: center;
	overflow-y: auto;
	overflow-x: hidden;
	backface-visibility: hidden;
	transform: translateZ(0);

	.sidebar {
		transition: none;
		backface-visibility: hidden;
		padding-top: 100px;

		@include breakpoint(mobile) {
			padding-top: 7.8125rem;
		}

		.name {
			margin-bottom: 1rem;
			text-transform: uppercase;
			font-size: 3.75rem;
			line-height: 1.26666;

			@include breakpoint(mobileLarge) {
				font-size: 10vw;
			}
		}

		.data {
			list-style-type: none;
			padding: 0;
			margin: 0 0 2.25rem 0;

			@include breakpoint(small) {
				margin-bottom: 35px;
			}

			li {
				font-size: 1rem;
				line-height: 1.625;
			}
		}

		.contact {
			display: flex;
			list-style-type: none;
			margin-bottom: 4.3125rem;
			padding: 0;

			@include breakpoint(small) {
				flex-direction: column;
				margin-bottom: 2rem;
			}

			li {
				a {
					border: solid 2px $colorBorder;
					border-radius: 40px;
					padding: .375rem .9375rem;
					margin-right: 1.1875rem;
					font-size: 1.25rem;
					transition: all ease .2s;

					@include breakpoint(small) {
						float: left;
						margin-right: 0;
						font-size: 14px;
						margin-bottom: 10px;
					}

					&:hover {
						border-color: #000;
						background: #000;
						color: #fff;
					}
				}
			}
		}

		.description {
			margin-bottom: 100px;

			@include breakpoint(small) {
				margin-bottom: 200px;
			}
		}
	}

	h1 {
		width: 100%;
	}

	&.show {
		opacity: 1;
	}

	&__close-btn__container {
		position: absolute;
		top: $pageGutterTablet;
		left: calc(50% + #{$gapTablet});
		z-index: 1100;

		@include breakpoint(small) {
			top: $pageGutter;
			left: calc(10% + #{$pageGutter});	
		}
	}

	.close-btn {
		
		width: 3rem;
		height: 3rem;
		border: solid 2px #000;
		border-radius: 40px;
		background-color: #000;
		transition: background-color ease 0.2s, transform ease 0.2s;
		
		@include breakpoint(xlarge) {
			top: $pageGutter;
			left: calc(10% + #{$gapTablet});
		}

		@include breakpoint(small) {
			left: calc(10% + #{$gap});
		}

		&:hover {
			transform: scale(1.1) translateZ(0);

			.inner {
				transform: translate(-50%,-50%) rotateZ(180deg);
			}		
		}

		&__container {
			
		}

		.inner {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 1.3125rem;
			height: 2px;
			background-color: #fff;
			transform: translate(-50%,-50%);
			transition: all ease 0.2s;
		}
	}
}