@import './../../../scss/variables/index.scss';
@import './../../../scss/general/breakpoints.scss';

.people {
	&__header {
		@include breakpoint(small) {
			margin-bottom: 3.25rem;
		}
	}

	&__list {
		@include breakpoint(small) {
			margin-bottom: 2.0625rem;
		}
	}

	&__item {
		grid-column-end: span 4;
		margin-bottom: 6.875rem;
		font-size: 1rem;
		line-height: 1.625;

		&__name {
			margin-top: .5625rem;
			text-transform: uppercase;
		}

		&__data {
			list-style: none;
			margin: 0;
			padding: 0;
		}

		.lazy-image-component {
			position: relative;
			height: 0;
			padding-bottom: 141.66760563%;
		}

		.people-video {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
		}
	}
}

.page.people {
	.toggle-filter-btn {
		bottom: $pageGutterTablet;

		@include breakpoint(small) {
			bottom: $pageGutter;
		}
	}

	.people-list {
		.col {
			&.portrait-thumb {
				&:nth-child(5n) {
					.viewport-visibility-component {
						transition-delay: 0.05s
					}
				}

				&:nth-child(5n+1) {
					.viewport-visibility-component {
						transition-delay: 0s
					}
				}

				&:nth-child(5n+2) {
					.viewport-visibility-component {
						transition-delay: 0.1s
					}
				}

				&:nth-child(5n+3) {
					.viewport-visibility-component {
						transition-delay: 0.2s
					}
				}
				&:nth-child(5n+4) {
					.viewport-visibility-component {
						transition-delay: 0.15s
					}
				}

			}
			
			&.empty {
				@include breakpoint(small) {
					display: none;
				}
			}

			> a {
			// .viewport-visibility-component > a {
				width: 100%;
				// position: relative;
			}

			margin-bottom: 90px;

			video.people-video {
				position: absolute;
				width: 100%;
				height: 100%;
				transition: opacity ease 0.3s;

				&.lazy-loading {
					opacity: 0;
				}

				&.loaded {
					opacity: 1;
				}
			}

			.name {
				font-size: 20px;
				margin-top: 30px;

				@include breakpoint(xlarge) {
					margin-top: 14px;
				}
			}

			.data {
				list-style-type: none;
				margin-top: 10px;
				padding: 0;
				font-size: 14px;
				margin-bottom: 0;
				display: flex;
				flex-direction: column;

				li {
					margin: 1px 0;
				}

				@include breakpoint(xlarge) {
					font-size: 12px;

					li {
						margin: 2px 0;
					}
				}

				@include breakpoint(mobileXLarge) {



				}
			}
		}

	}	
}