@import './../../../scss/variables/index.scss';
@import './../../../scss/general/breakpoints.scss';

.brandlogo {
	// position: absolute;
	z-index: 1200;
	mix-blend-mode: difference;
	font-size: 1.125rem;
	line-height: 1;
	text-transform: uppercase;
	color: #000;

	&__svg {
		&--mobile {
			display: none;

			@include breakpoint(small) {
				display: block;
			}
		}

		&--desktop {
			@include breakpoint(small) {
				display: none;
			}	
		}
	}

	svg {
		width: 100%;
		height: 1rem;
		fill: currentColor;

		@include breakpoint(small) {
			height: 2.5rem;
		}
	}
	
	a {
		display: flex;
		align-items: center;
		color: #fff;
	}
}

.menu-open .brandlogo a {
	@include breakpoint(xlarge) {
	 	color: #fff;
	 } 
}

.back .brandlogo {
	z-index: 0;
}

.back.menu-open .brandlogo {
	z-index: 1000;
}