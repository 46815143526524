/* COLORS */
$colorMainBlack: #000;
$colorMainWhite: #fff;
$colorBorder: #000;

/* MARGINS */
$scrollBarWidth: 15;

$marginGlobalTop: 25px;
$marginGlobalBottom: 25px;
$marginGlobalRight: #{20 + $scrollBarWidth}px;
$marginGlobalLeft: 20px;

$marginRightPageContent: 85px;
$marginRightPageContentWithoutScrollbar: #{70+$scrollBarWidth}px;
$centerOfPage: calc((100% - #{$marginRightPageContentWithoutScrollbar} + 10px)/2);

$sidebarWidth: calc((100% - 54px) / 2 + 54px);

$gridMargin: 20px;
$gridMarginLeft: 10px;
$gridMarginRight: 50px;

$pageSectionMargin: 10px;
$pageSectionMarginMobile: 17px;
$pageSectionMarginBottomMobile: 22px;

$gridColWidth: 6.25%;
$gridColMargin: 10px;
$gridColMarginMob: 3px;

$pagePaddingTopMain: 33vh;
$pagePaddingTopMainMobile: 200px;


// Grid
$gap: 1rem;
$gapTablet: 2.5rem;
$pageGutter: 1.25rem;
$pageGutterTablet: $gapTablet;

// Animation
$sidebarAnimationTimeEase: 800ms cubic-bezier(0.84, 0.07, 0.07, 1.01);
$sidebarAnimation: all $sidebarAnimationTimeEase;