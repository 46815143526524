@import './../../../scss/variables/index.scss';
@import './../../../scss/general/breakpoints.scss';

.loadwrapper {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	// transform: translateY(30%);
	overflow-x: hidden;
}

.scroll-wrapper {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	overflow-y: scroll;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;

	&.disable {
		overflow: hidden;
		width: 100%;
	}

	&.hide {
		transition: all cubic-bezier(0.93, 0.02, 0.17, 0.99) 0.3s;
		opacity: 0;
		transform: translateY(10px);
	}

	&.show {
		transform: translateY(0);
		transition: all cubic-bezier(0.93, 0.02, 0.17, 0.99) 0.6s;
		opacity: 1;
	}

	&__header {
		position: absolute;
		display: flex;
		width: 100%;
		padding: $pageGutterTablet;
		background-color: $colorMainWhite;
		
		@include breakpoint(small) {
			padding: 1.375rem $pageGutter $pageGutter;
		}
	}

	&__logo {
		.brandlogo {
			position: relative;
			top: 0;
			left: 0;
		}
	}
}