@import './../../../scss/variables/index.scss';

.lazy-image-component {
	background-color: #efefef;
	position: relative;
	width: 100%;
	overflow: hidden;
	animation-name: imageload;
	animation-duration: 1.4s;
	animation-iteration-count: infinite;

	&.lazyloading {
		img {
			opacity: 0;
		}
	}

	&.loaded {
		img {
			opacity: 1;
		}
	}

	img {
		// opacity: 0;
		transition: opacity ease-in-out 0.7s, transform ease-in-out 0.3s;
		// position: absolute;
		// width: 100%;
		// height: 100%;
		// top: 0;
		// left: 0;
		backface-visibility: hidden;
	}
}

@keyframes imageload {
	0% {background-color: #efefef}
	50% {background-color: #e2e2e2}
	100% {background-color: #efefef}
}