@import './breakpoints.scss';
@import './../variables';

body {
	font-size: 1.25rem;
	line-height: 1.5;

	@include breakpoint(small) {
		font-size: 1rem;
		line-height: 1.625;
	}
}

a {
	text-decoration: none;
	color: #000;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-family: $fontLight;
  line-height: 1;
}

h1 {
	font-size: 52px;

	@include breakpoint(large) {
		font-size: 40px;
	}

	@include breakpoint(mobile) {
		font-size: 8vw;
	}
}

p {
	margin-top: 0;
	width: 100%;

	&:first-of-type {
		margin-top: 0;
	}

	@include breakpoint(small) {
			margin-bottom: $pageSectionMarginBottomMobile;
		}

	a {
		text-decoration: underline;
	}
}

.f-home-headline,
.f-section-headline {
	text-transform: uppercase;
	font-size: 3.75rem;
	line-height: 1.2;
	font-weight: 400;

	@include breakpoint(large) {
		font-size: 2rem;
	}
}

.f-home-headline {
	@include breakpoint(small) {
		font-size: 2.125rem;
	}	
}

.f-section-headline {	
	@include breakpoint(small) {
		font-size: 1.5rem;
		line-height: 1.4166666667;
	}
}

.f-page-headline {
	text-transform: uppercase;

	&,
	p {
		font-size: 5rem;
		line-height: 1.2;

		@include breakpoint(large) {
			font-size: 3rem;
		}

		@include breakpoint(small) {
			font-size: 1.5rem;
		}
	}

	a {
		display: inline-flex;
		align-items: center;
		text-decoration: none;

		&:hover .linkwrapper__arrow {
			z-index: 2;
		}
	}
}

.c-arrow {
	position: relative;
	width: auto;
	height: .75em;
}