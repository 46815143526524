@import './../../../scss/variables/index.scss';
@import './../../../scss/general/breakpoints.scss';

.cursor {
	position: fixed;
	z-index: 10000;
	will-change: opacity;
	transition: opacity 200ms ease-in-out;
	pointer-events: none;
	mix-blend-mode: difference;
	
	
	&.hide {
		opacity: 0;
	}

	&__pointer,
	&__text {
		position: absolute;
	}

	&__pointer {
		top: -9px;
		left: -9px;
		width: 18px;
		height: 18px;
		border: solid 1px #fff;
		background-color: #fff;
		border-radius: 50%;
		box-sizing: border-box;
		pointer-events: none;
		will-change: transform, background-color;
		transition: transform 200ms ease-in-out, background-color 200ms ease-in-out;
	}
	
	&__text {
		top: 1em;
		left: -5em;
		display: inline-block;
		width: 10em;
		color: #fff;
		will-change: opacity;
		transition: opacity 200ms ease-in-out;
		opacity: 0;
		text-align: center;
		text-transform: uppercase;
		font-size: 2rem;
		
		&--large {
			font-size: 3.125rem;

			@include breakpoint(small) {
				font-size: 1.5rem;
			}
		}
	}

	&.active &__text {
		opacity: 1;
	}
	
	&.active &__pointer {
		transform: scale(3);
		background-color: transparent;
	}
}