@import './../../../scss/variables/index.scss';
@import './../../../scss/general/breakpoints.scss';

.currentSingleName {
	position: absolute;
	top: $marginGlobalTop;
	// left: calc((100% - $marginRightPageContent - 10px) / 2 + 10px);
	right: $centerOfPage;
	padding-right: 30px;
	// margin-right: $marginRightPageContent;
	text-transform: uppercase;
	font-size: 20px;
	z-index: 100;

	@include breakpoint(large) {
		display: none;
	}
}