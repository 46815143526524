@import "../../../scss/variables/index.scss";
@import "../../../scss/general/breakpoints.scss";

.linkwrapper {
  // position: relative;
}

.portrait-thumb .linkwrapper,
.related-projects .project-thumb .linkwrapper,
.archive-list .project-thumb .linkwrapper {
  width: 100%;
}

.archive-list {
	.project-thumb {
		position: relative;

		&:hover .title {
			opacity: 1;
		}
	}

	.title {
		position: absolute;
		display: inline-block;
		width: 100%;
		text-transform: uppercase;
		will-change: opacity;
		transition: opacity 200ms ease-out;
		text-align: center;
		opacity: 0;

		@include breakpoint(small) {
			position: relative;
			opacity: 1;
			text-align: left;
			font-size: .9375rem;
		}
	}

	.notfound {
		align-self: center;
	}
}
