@import './../../../scss/variables/index.scss';
@import './../../../scss/general/breakpoints.scss';

.page.project-single {	
	.page-section {
		&.headline {
			transition: $sidebarAnimation;
		}

		&.full {
			.caption {
				left: 10px;
			}
		}

		&.video {
			.viewport-visibility-component {
				padding-bottom: 56.25%;

				iframe {
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
				}
			}
		}

		.col {
			transform: translateZ(0);
			


			.inner, img {
				transform: translateZ(0);
			}
		}
	}
}


.project-single {
	&__hero {
		position: relative;
		display: flex;
		align-items: flex-end;
		margin-bottom: 1.375rem;
		padding-bottom: $pageGutterTablet;
		overflow: hidden;

		@include breakpoint(small) {
			margin-bottom: 0;
			padding-bottom: 0.5rem;
		}
		
		&--image {
			height: 100vh;
			color: #fff;

			@include breakpoint(small) {
				height: 540px;
			}
		}

		.page-section {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: 0;
		}

		.viewport-visibility-component,
		.media {
			height: 100%;
		}

		.media {
			aspect-ratio: unset;
			transition: $sidebarAnimation;

			.open & {
				width: 50%;
			}
		}
	}

	&__title-container {
		position: relative;
		z-index: 1;
		margin-bottom: .6875rem;
	}

	&__title {
		margin: 0;
		overflow-wrap: break-word;
		text-transform: uppercase;
		font-size: 7.5rem;

		@include breakpoint(xlarge) {
			padding-right: 6.25rem;
			font-size: 3.75rem;
		}

		@include breakpoint(mobileLarge) {
			padding-right: 0;
			font-size: 2.75rem;
		}
	}

	&__info {
		&__toggle {
			position: absolute;
			top: 1.1875rem;
			right: $pageGutterTablet;
			will-change: transform;
			transition: transform $sidebarAnimationTimeEase;
			
			@include breakpoint(small) {
				order: 3;
				position: relative;
				right: auto;
				font-size: 1rem;
			}

			.open & {
				transform: translateX(200%);
			}
		}
	}

	&__data {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 15.125rem;

		@include breakpoint(xlarge) {
			margin-top: 2.1875rem;
			margin-bottom: 6.375rem;
		}

		@include breakpoint(small) {
			margin-top: 0;
		}

		& &__title {
			width: 100%;
			margin-top: 1.1875rem;
			margin-bottom: 1.875rem;
			font-size: 1em;
			text-transform: uppercase;

			@include breakpoint(small) {
				margin-bottom: 1rem;
			}
		}

		&__rows {
			width: 100%;

			@include breakpoint(small) {
				margin-bottom: 2rem;
			}
		}

		&__row {
			display: flex;
			width: 100%;
			font-size: 1.25rem;
			line-height: 1.5;

			@include breakpoint(small) {
				font-size: 1em;
				line-height: 1.875;
			}

			@include breakpoint(mobile) {
				font-size: 1em;
			}

			.title {
				flex: 0 0 200px;
				padding-right: 20px;

				@include breakpoint(small) {
					flex: 0 0 140px;	
				}

				@include breakpoint(mobile) {
					flex: 0 0 140px;
				}
			}
		}

		& + .page-section {
			margin-top: 0;
		}
	}

	.caption {
		margin-top: .5rem;
		font-size: .875rem;

		&.right {
			margin-right: 0;
			margin-left: auto;
		}
	}

	&__text {
		.quote {
			margin: 0;

			@include breakpoint(small) {
				font-size: 2.1875rem;
				line-height: 1.2;
			}
		}
	}

	&__two-images {
		align-items: flex-end;

		@include breakpoint(small) {
			row-gap: 2.5rem;
		}

		&__image {
			&:first-child .media {
				transform-origin: left center;
			}

			&:last-child .media {
				transform-origin: right center;
			}

			.media {
				will-change: transform;
				transition: transform 200ms ease-in-out;
				
				.open & {
					transform: scale(1.2);
				
					@include breakpoint(small) {
						transform: none;
					}
				}
			}

			&--default {
				grid-column-end: span 6;
				
				&:last-child {
					grid-column-start: 11;
				}

				@include breakpoint(small) {
					grid-column-start: 1;
					grid-column-end: span 3;
				}
			}

			&--large {
				grid-column-end: span 7;
				
				&:last-child {
					grid-column-start: 10;
				}
			}

			&--default,
			&--large {
				&,
				&:last-child {
					@include breakpoint(small) {
						grid-column-start: auto;
						grid-column-end: span 3;
					}
				}
			}

			&:last-child {
				@include breakpoint(small) {
					grid-column-start: 2;
				}
			}
		}

		.caption:empty {
			display: none;
		}
	}

	&__image-large {
		&__image {
			grid-column-start: 4;
			grid-column-end: span 10;
			
			@include breakpoint(small) {
				grid-column-start: 1;
				grid-column-end: span 16;
			}
		}

		.media {
			will-change: transform;
			transition: transform 200ms ease-in-out;
			.open & {
				transform: scale(1.2);
			}
		}

		.caption:empty {
			display: none;
		}
	}

	&__related-container {
		position: relative;
		z-index: 1000;
		margin-top: 18.75rem;
		padding-top: 2.375rem;
		padding-bottom: 15.25rem;
		background-color: #fff;
		border-top: 2px solid currentColor;

		@include breakpoint(small) {
			padding-bottom: 5.1875rem;
		}
		
		.l-columns-container--outmost {
			width: 100%;
		}

		.full + & {
			margin-top: 0;
		}
	}

	&__related {
		margin-bottom: 0;

		&__items {
			display: grid;
			grid-template-columns: repeat(5, 1fr);
			gap: $gapTablet;
			align-items: flex-end;
			margin-top: 9.375rem;

			@include breakpoint(small) {
				align-items: flex-start;
				margin-top: 4.3125rem;
				grid-template-columns: repeat(2, 1fr);
				gap: $gap;
			}
		}

		&__item {
			position: relative;

			@include breakpoint(small) {
				margin-top: 0.25rem;
				margin-bottom: 4rem;
			}
			
			.title {
				display: block;
				max-width: calc(50vw - #{$pageGutter * 1.5});
				margin-top: 0.125rem;
				font-size: .9375rem;
				line-height: 1.2;
				text-transform: uppercase;
				overflow: hidden;
				text-overflow: ellipsis;
				
				@include breakpoint(small) {
					margin-top: 0.3125rem;
				}
			}

			&:hover .title {
				opacity: 1;
			}
		}
	}
}

.project-section,
.project-single__data {
	transition: $sidebarAnimation;
}

.project-section {
	transform: translateZ(0);
}