:root {
	--app-height: 100%;
}

html, body {
	position: fixed;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-color: #fff;
}

body {
  margin: 0;
  font-family: $fontMain;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
}

body, html, * {
	box-sizing: border-box;
}

iframe,
figure,
img,
video {
	display: block;
	width: 100%;
}

figure {
	margin: 0;
}

.media {
	position: relative;
	height: 0;

	&--landscape {
		padding-bottom: 66.66666%;
		
		.open .full & {
			padding-bottom: 33.3333%;
		}
	}

	&--portrait {
		padding-bottom: 125%;
		
		.open .full & {
			padding-bottom: 62.5%;
		}
	}

	.full & {
		&,
		img,
		picture,
		video {
			transition: $sidebarAnimation;
		}
	}

	.open .full & {
		img,
		picture,
		video {
			width: 50%;
		}
	}

	img,
	picture
	video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	iframe {
		width: 100%;
		height: 100%;
	}

	.lazy-image-component,
	.lazy-loading {
		width: 100%;
		height: 100%;
	}

	&__caption-container {
		display: flex;
		
		.right & {
			justify-content: flex-end;
		}
	}

	&__caption {
		width: auto;
		margin-top: 0.5rem !important;
	}
}

.page.post img {
	height: auto;
}

.btn-container {
	display: flex;
	flex-wrap: wrap;

	.btn {
		margin-right: .5rem;
		margin-bottom: .5rem;
	}
}

.btn {
	display: flex;
	align-items: center;
	height: 2.4em;
	padding-right: 1.25rem;
	padding-left: 1.25rem;
	background-color: #000;
	border-radius: 2rem;
	color: #fff;
	line-height: 1;

	&--inline {
		display: inline-flex;
		margin-right: .5rem;
		margin-bottom: .5rem;
	}

	p {
		flex: 1 1 auto;
		margin: 0;
		white-space: nowrap;
	}

	&__plus {
		flex: 0 0 .8em;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		height: .8em;
		margin-right: .75rem;
		margin-left: 1rem;

		&:before,
		&:after {
			content: '';
			position: absolute;
			background-color: currentColor;
		}

		&:before {
			width: 100%;
			height: 2px;

			@include breakpoint(small) {
				height: 1px;
			}
		}

		&:after {
			width: 2px;
			height: 100%;

			@include breakpoint(small) {
				width: 1px;
			}
		}
	}
}