@import './../../../scss/variables/index.scss';
@import './../../../scss/general/breakpoints.scss';

.project-archive {

	&__tags {
		margin-top: 8.6875rem;

		@include breakpoint(small) {
			margin-top: 9.375rem;
		}

		.active-filter-tags {
			min-height: 4rem;
		}
	}

	.archive-list {
		will-change: width, opacity;
		transition: width 300ms ease-in-out, opacity 300ms ease-in-out, gap 300ms ease-in-out;
		opacity: 1;
		margin-top: 0;
		min-height: 500px;

		> .inner {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			transition: opacity ease-in-out 0.15s;

			&.hide {
				opacity: 0.5;

				@include breakpoint(xlarge) {
					opacity: 1;
				}
			}
		}

		&.open {
			> .inner {
				width: 50%;

				@include breakpoint(xlarge) {
					width: 100%;
				}

				.col4 {
					width: 50%;

					@include breakpoint(xlarge) {
						width: 25%;
					}

					@include breakpoint(small) {
						width: 50%;
					}
				}
			}
		}

		&.loading {
			opacity: 0;
		}
		
		.col {
			margin-bottom: 61px;
			// width: 50%;
		}

		.notfound {
			font-style: italic;
			text-align: center;
			width: 100%;
			margin-top: 20px;
		}
	}
}

.archive-list {
	.title {
		opacity: 1;
	}

  &--spacy {
    align-items: flex-end;

		@include breakpoint(small) {
			margin-bottom: 3.9375rem;
		}

    .project-thumb {
      margin-bottom: 17.75rem;

			@include breakpoint(small) {
				margin-bottom: 5rem;
			}

      &:nth-child(5n + 1),
      &:nth-child(5n + 4) {
				.media {
					transform-origin: left center;
				}
			}

			&:nth-child(5n + 2),
      &:nth-child(5n+ 5) {
				.media {
					transform-origin: right center;
				}
			}

      &:nth-child(5n + 1) {
        grid-column-start: 1;
        grid-column-end: span 6;

				@include breakpoint(small) {
					grid-column-end: span 3;
				}
      }
			
      &:nth-child(5n + 2) {
				grid-column-start: 10;
        grid-column-end: span 7;

				@include breakpoint(small) {
					grid-column-start: 2;
					grid-column-end: span 3;
				}
      }

      &:nth-child(5n + 3) {
        grid-column-start: 4;
        grid-column-end: span 10;

				@include breakpoint(small) {
					grid-column-start: 1;
					grid-column-end: span 4;
				}
      }

      &:nth-child(5n + 4) {
        grid-column-start: 1;
        grid-column-end: span 7;

				@include breakpoint(small) {
					grid-column-end: span 3;
				}
      }

      &:nth-child(5n + 5) {
        grid-column-start: 11;
        grid-column-end: span 6;

				@include breakpoint(small) {
					grid-column-start: 2;
					grid-column-end: span 3;
				}
      }

			&:nth-child(5n + 2),
			&:nth-child(5n + 5) {
				.title {
					right: 50%;
					left: auto;

					@include breakpoint(small) {
						right: auto;
					}
				}
			}
    }

		.media {
			will-change: transform;
			transition: transform 300ms ease-in-out;

			.open & {
				transform: scale(1.2);

				@include breakpoint(small) {
					transform: none;
				}
			}
		}

		&:not(.archive-list--mobile) {
			.title {
				display: none;
			}
		}

		.notfound {
			grid-column-end: span 16;
		}
  }

	&--simple {
		grid-template-columns: repeat(4, 1fr);
		gap: 11.1111vw;
		align-items: center;
		margin-bottom: 18.75rem;

		@include breakpoint(small) {
			margin-top: 5.9375rem;
			margin-bottom: 8.9375rem;
			gap: $gap;
		}

		.open & {
			gap: 4vw;
		}

		.project-thumb {
			@include breakpoint(small) {
				grid-column-end: span 2;
			}
		}

		a {
			display: block;
		}

		.title {
			width: 100%;
			font-size: .9375rem;
			text-align: left;
		}

		.notfound {
			grid-column-end: span 5;

			@include breakpoint(small) {
				grid-column-start: 1;
				grid-column-end: span 4;
			}
		}
	}
}