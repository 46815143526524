@import './../../../scss/variables/index.scss';
@import './../../../scss/general/breakpoints.scss';

.footer {
	position: relative;
	z-index: 1000;
	padding-top: 2.4375rem;
	padding-bottom: 2.375rem;
	background-color: #fff;
	border-top: 2px solid currentColor;
	text-transform: uppercase;

	@include breakpoint(small) {
		row-gap: 0;
		padding: 0;
		font-size: .9375rem;
	}

	.open & {
		width: 100%;
	}

	&__column {
		grid-column-end: span 4;
		
		@include breakpoint(small) {
			padding: 1rem $pageGutter;
		}

		&:first-child {
			grid-column-end: span 8;

			@include breakpoint(small) {
				border-bottom: 2px solid #000;
			}
		}

		&:last-child {
			grid-column-end: span 4;
			text-align: right;
			
			@include breakpoint(small) {
				text-align: left;
			}	
		}

		@include breakpoint(small) {
			grid-column-end: span 4;
			text-align: left;
		}
	}

	&__text {
		margin: 0;
	}

	&__link {
		&:not(:last-child) {
			margin-right: 3rem;
		}
	}
}