@import "./../../../scss/variables/index.scss";
@import "./../../../scss/general/breakpoints.scss";

.sidebar {
  position: fixed;
  min-height: 100%;
  right: 0;
  top: 0;
  z-index: 1000;
  width: calc(50% + 1px);
  height: 100%;
  padding: 8.125rem $gapTablet calc(6.8125rem + #{$pageGutterTablet});
  background-color: #fff;
  border-left: solid $colorBorder 2px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  transition: transform cubic-bezier(0.84, 0.07, 0.07, 1.01) 0.8s;
  transform: translateX(100%);

  @include breakpoint(xlarge) {
    width: 90%;
  }

  @include breakpoint(small) {
    padding-right: $pageGutter;
    padding-bottom: $pageGutter;
    padding-left: $pageGutter;
  }

  &.archivefilter {
    padding-top: 16.125rem;

    @include breakpoint(small) {
      padding-top: 8rem;
    }
  }

  &.open {
    transform: translateX(0%);
  }

  &__top {
    display: flex;
    align-items: center;
  }

  &__headline {
    text-transform: uppercase;
  }

  .sidebar-sections {
    display: flex;
    flex-direction: column;

    // .btn_download_file {
    //   padding: 12px 20px;
    //   background: #fff;
    //   border-radius: 40px;
    //   border: 2px #d9d9d9 solid;
    //   margin: 0 0 45px;
    //   align-self: flex-start;
    //   display: inline-block;
    //   transition: all ease 0.2s;

    //   &:hover {
    //     background: #000;
    //     color: #fff;
    //     border: 2px #000 solid;
    //   }
    // }

    .lazy-image-component {
      width: 100%;
      min-width: 100%;
      margin-bottom: 45px;
    }
  }
}

.sidebar__toggle,
.side-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
	border: solid 2px #000;
	border-radius: 50%;
	background-color: #fff;
	transition: background-color 0.2s ease, color 200ms ease, transform 0.2s ease;
	backface-visibility: hidden;
	cursor: pointer;

  &.open,
  &:hover {
		background: #000;
    color: #fff;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.sidebar__toggle {
  position: absolute;
  top: $pageGutterTablet;
  left: $pageGutterTablet;

  @include breakpoint(small) {
    top: $pageGutter;
    left: $pageGutter;
  }

  &__container {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.side-btn {
	position: fixed;
	right: $pageGutterTablet;
  z-index: 80;
  	
	@include breakpoint(small) {
		right: $pageGutter;
	}
}


.toggle-filter-btn {
  bottom: calc(#{$pageGutterTablet} + 58px);
  padding: 0.8125rem 0.625rem;
  
  @include breakpoint(small) {    
    bottom: calc(2.5rem + 38px);
  }
}

.toggle-layout-btn {
	bottom: $pageGutterTablet;
  padding: 0.8125rem 0.625rem;	

  @include breakpoint(small) {
    bottom: $pageGutter   
  }
}

.toggle-sidebar-btn {
	&.open,
  &:hover {
    .inner {
      div {
        &.close {
          background: #fff;
        }
      }
    }
  }

  .inner {
    position: absolute;
    transition: all ease-in-out 0.3s;

    div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all ease 0.4s;

      &.open {
        height: 24px;
        svg {
          #I2 {
            transition: all ease 0.2s;
          }
        }
      }

      &.close {
        opacity: 0;
        height: 1rem;
        width: 2px;
        background: #000;
      }
    }
  }

  &.open {
    .inner {
      transform: rotateZ(-90deg) translateZ(0);

      .open {
        opacity: 0;
      }

      .close {
        opacity: 1;
      }
    }
  }
}
