@import './../../../scss/fonts/fonts';
@import '../../../scss/variables/index';
@import '../../../scss/general/breakpoints';

.headline-image-text,
.headline-text,
.text,
.text-columns,
.two-columns-image-text {
  .btn {
    display: inline-flex;
    background-color: transparent;
    text-decoration: none;
    color: currentColor;
    border: 2px solid #000;
    will-change: background-color, color;
    transition: background-color 200ms ease-in, color 200ms ease-in;

    &:hover {
      background-color: #000;
      color: #fff;
    }
  }
}

.headline-image-text,
.headline-text,
.two-columns-image-text {
  &__text p:last-child {
    a:not(.btn):first-child:last-child {
      display: inline-flex;
      align-items: center;
      text-transform: uppercase;
      text-decoration: none;

      &:before {
        content: "↪";
        content: "\21AA\FE0E";
        position: relative;
        top: 0.1em;
        margin-right: 0.25em;
        font-size: 1.25em;
      }

      @include breakpoint(small) {
        margin-top: 0.3125rem;
      }

    }

    .page.post & a:not(.btn) {
      display: inline;

      &:before {
        content: none;
      }
    }
  }
}

.headline-image-text,
.headline-text {
  &__headline {
    position: sticky;
    align-self: flex-start;
    top: 3.125rem;

    @include breakpoint(small) {
      position: relative;
      top: auto;
    }
  }
}

.headline-image-text,
.two-columns-image-text {
  .media__figure + &__text,
  .media + &__text {
    margin-top: 1.75rem;

    @include breakpoint(small) {
      margin-top: 2.5rem;
    }
  }
}

.page-section.headline {
  margin-bottom: 0;

  & + .page-section {
    @include breakpoint(small) {
      margin-top: 1.875rem;
    }
  }
}



.headline-image-text {
  &__headline,
  &__content {
    grid-column-end: span 8;
    
    @include breakpoint(small) {      
      grid-column-end: span 4;
    }
  }

  .l-columns-container {
    @include breakpoint(small) {
      row-gap: 2.125rem;
    }
  }
}

.headline-text {
  &__headline,
  &__text {
    grid-column-end: span 8;
    
    @include breakpoint(small) {      
      grid-column-end: span 4;
    }
  }

  .l-columns-container {
    @include breakpoint(small) {
      row-gap: 1.875rem;
    }
  }
}

.image-full {
  &.page-section {
    margin: 0;
  }
  
  .media {
    height: 100vh;
    height: var(--app-height);
    overflow: hidden;
    padding-bottom: 0;
  }

  img,
  video {
    height: 100%;
    object-fit: cover;
  }

  .title-text {
    padding-top: 0;
  }
}

.image-large,
.video {
  .media,
  .media__figure {
    grid-column-start: 4;
    grid-column-end: span 10;
    
    @include breakpoint(small) {      
      grid-column-start: 1;
      grid-column-end: span 4;
    }
  }
}

.people__header,
.large-text {
  .f-section-headline {
    font-family: $fontMain;

    @include breakpoint(medium) {
      font-size: 2.125rem;
      line-height: 1.2;
    }
  }
}

.lasso {
  position: relative;
  height: 100vh;
  height: var(--app-height);
  margin: 0;

  .sections .viewport-visibility-component:not(:first-child) & {
    @include breakpoint(small) {
      height: 66vh;
    }
  }
}

.latest-news {
  position: relative;
  // z-index: -1;
  
  @include breakpoint(small) {
    height: auto !important;
  }

  &:last-child {
    margin-bottom: 2.1875rem;
    
    .home & {
      padding-top: 5rem;

      @include breakpoint(small) {
        padding-top: 3.125rem;
      }
    } 
  }


  &__inner {
    // position: fixed;
    // bottom: 6.75rem;

    @include breakpoint(small) {
      position: static !important;
    }
  }

  &__items {
    align-items: flex-start;
    text-transform: uppercase;

    @include breakpoint(small) {
      margin-top: 2.125rem;
      font-size: .9375rem;
    }
  }

  &__item {
    grid-column-end: span 4;

    @include breakpoint(small) {      
      // grid-column-end: span 8;
    }

    &__image-container {
      position: relative;
      height: 0;
      padding-bottom: 150%;
      margin-bottom: 1.3125rem;

      @include breakpoint(small) {
        height: auto;
        margin-bottom: 0.0625rem;
        padding-bottom: 0;
      }
    }

    img {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      height: auto;

      @include breakpoint(small) {
        position: relative;
      }
    }
  }
}

.people {

}

.slideshow {
  position: relative;

  .swiper {
    // contain: paint;
    overflow: visible;
    -webkit-clip-path: inset(0); /* safari*/
    clip-path: inset(0);
    clip: rect(0px, auto, auto, 0px)
  }

  .page-section {
    position: static;
    margin: 0;
  }
  
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    height: var(--app-height);
  }

  .image-full {
    max-height: 100vh;
  }

  // .title-text {
  //   bottom: 16rem;
  // }

  &__btn {
    position: absolute;
    top: calc(50% - 1.5rem);
    z-index: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    will-change: background-color;
    transition: background-color 200ms ease-out;
    background-color: rgba(0,0,0,.2);
    color: #fff;

    &:hover {
      background-color: rgba(0,0,0,1);
    }

    svg {
      will-change: transform;
      transition: transform 150ms ease-out;
    }

    &--prev {
      left: $pageGutterTablet;

      @include breakpoint(small) {
        left: $pageGutter;
      }

      &:hover svg {
        transform: translateX(-.1rem);
      }
    }

    &--next {
      right: $pageGutterTablet;

      @include breakpoint(small) {
        right: $pageGutter;
      }

      svg {
        transform: rotate(180deg);
      }

      &:hover svg {
        transform: rotate(180deg) translateX(-.1rem);
      }
    }
  }
}

.text-columns {
  &__column {
    grid-column-end: span 4;

    @include breakpoint(xlarge) {
      grid-column-end: span 8;  
    }
  }

  .headline + & {
    margin-top: 7.5rem;
  }
}

.video {
  .media {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
  }

  iframe {
    position: absolute;
  }
}

.section-border {
  height: 2px;
  background-color: currentColor;
}

.two-columns-image-text {
  align-items: flex-end;

  &__column {
    &--left {
      grid-column-end: span 7;

      @include breakpoint(small) {
        grid-column-end: span 4;
      }
    }

    &--right {
      grid-column-start: 11;
      grid-column-end: span 6;
      
      @include breakpoint(small) {
        grid-column-start: 1;
        grid-column-end: span 4;
      }
    }
  }
}

.two-images {
  align-items: flex-end;

  @include breakpoint(small) {
    row-gap: 2rem;
  }

  &__left {
    grid-column-end: span 7;
    
    @include breakpoint(small) {
      grid-column-end: span 3;
    }
  }

  &__right {
    grid-column-start: 11;
    grid-column-end: span 6;

    @include breakpoint(small) {
      grid-column-start: 2;
      grid-column-end: span 3;
    }
  }

  &--right &__left {
    grid-column-end: span 6;
  }

  &--right &__right {
    grid-column-start: 10;
    grid-column-end: span 7;
  }
}

.template-1 {

}